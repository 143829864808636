import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { CardRecord, CARD_FIELDS, CloverTokenResponse, CloverValidationError } from "src/app/storage/retail/retail.state";
import { CLOVER_STYLES } from "./clover-styles";
import { AbstractControl, UntypedFormBuilder, NgForm, ValidatorFn, Validators } from "@angular/forms";
import { DOCUMENT } from "@angular/common";
import isEmpty from "ramda/es/isEmpty";

declare const Clover: any;

@Component({
    selector: "retail-credit-card-form",
    templateUrl: "./credit-card-form.component.html",
    styleUrls: ["./credit-card-form.component.scss"],
})
export class RetailCreditCardFormComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {

    @Input() pakmsKey: string = "mt_vitupay_test_api_key";
    @Input() agencyFeeDescriptor: string;
    @Input() serviceFeeDescriptor: string;
    @Input() cardErrorMessage: string;

    @Input() set record(value: CardRecord) {
        this.cardForm.patchValue(value, { emitEvent: false });
    }
    @Output() recordChange = new EventEmitter<CardRecord>();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private fb: UntypedFormBuilder,
        private parentForm: NgForm,
    ) {}

    CARD_FIELDS = CARD_FIELDS;

    clover: any = null;
    cloverFields: Record<CARD_FIELDS, string> = {
        [CARD_FIELDS.CARD_NUMBER]: "#card-number",
        [CARD_FIELDS.CARD_DATE]: "#card-date",
        [CARD_FIELDS.CARD_CVV]: "#card-cvv",
        [CARD_FIELDS.CARD_POSTAL_CODE]: "#card-postal-code",
    };

    cloverFieldErrors: Record<CARD_FIELDS, string> = {
        [CARD_FIELDS.CARD_NUMBER]: "Card number is required.",
        [CARD_FIELDS.CARD_DATE]: "Card expiry is required.",
        [CARD_FIELDS.CARD_CVV]: "Card CVV is required.",
        [CARD_FIELDS.CARD_POSTAL_CODE]: "Card postal code is required.",
    };

    cloverFieldValidator = (field: string): ValidatorFn => {
        return () => this.cloverFieldErrors[field]
            ? { [field]: this.cloverFieldErrors[field] }
            : null;
    }

    cardForm = this.fb.group({
        cardHolderName: [null, [Validators.required, Validators.maxLength(100)]],
        accept: [false, [Validators.required, (control: AbstractControl) => !control.value ? { termsNotAccepted: true } : null]],
        customerEmail: [null, [Validators.required, Validators.email]],
        ...Object.fromEntries(
            Object.keys(this.cloverFields)
            .map(i => [i, [null, this.cloverFieldValidator(i)]])
            ),
        });

    ngOnInit(): void {

        this.parentForm.form.addControl("cardGroup", this.cardForm);
        this.cardForm.valueChanges.subscribe(i => this.recordChange.emit(i));
    }

    ngOnDestroy(): void {

        this.parentForm.form.removeControl("cardGroup");
    }

    ngOnChanges(changes: SimpleChanges): void {

        if ("cardErrorMessage" in changes && changes.cardErrorMessage.currentValue) {
            this.resetCloverForm();
            this.cardForm.reset();
        }
    }

    ngAfterViewInit(): void {

        this.clover = new Clover(this.pakmsKey);
        setTimeout(() => this.renderCardFields());
    }

    touched = (field: string) => this.cardForm.controls[field].touched;
    hasError = (field: string) => this.cardForm.controls[field].hasError(field);
    getError = (field: string) => this.cardForm.controls[field].errors[field];

    public resetCloverForm(): void {

        Object.values(this.cloverFields)
            .forEach(i => this.document.querySelector(i).firstChild.remove());

        this.renderCardFields();
    }

    private renderCardFields(): void {

        const elements = this.clover.elements();

        Object.keys(this.cloverFields).forEach(i => {

            const field = elements.create(i, CLOVER_STYLES);
            field.mount(this.cloverFields[i]);

            field.addEventListener("change", (error: CloverValidationError) => this.onCloverFieldChange(i, error));
            field.addEventListener("blur", (error: CloverValidationError) => this.onCloverFieldBlur(i, error));
        });
    }

    public async getTokenData(): Promise<CloverTokenResponse | { error: "validationError" | "generationError" }> {

        const tokenData: CloverTokenResponse = await this.clover.createToken(true);

        if (isEmpty(tokenData)) {

            return { error: "generationError" };
        }

        if (tokenData.errors) {

            Object.keys(this.cloverFields)
                .filter(i => tokenData.errors[i])
                .forEach(i => {
                    this.cardForm.controls[i].setErrors({ [i]: tokenData.errors[i] });
                });

            return { error: "validationError" };
        }

        return {
            ...tokenData,
            ...this.cardForm.value,
        } as CloverTokenResponse;
    }

    private onCloverFieldBlur(field: string, error: CloverValidationError): void {
        this.cardForm.controls[field].markAsTouched();
    }

    private onCloverFieldChange(field: string, error: CloverValidationError): void {

        this.cloverFieldErrors[field] = error[field].error;
        this.cardForm.controls[field].updateValueAndValidity();
    }
}
